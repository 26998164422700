<template>
  <svg width="21" height="16" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.688 7.012v8.74c0 .689.644 1.248 1.439 1.248h14.387c.795 0 1.44-.559 1.44-1.248v-8.74"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="m13.655 12.006-3.52-2.347v4.693l3.52-2.346zM1.239 3.77C.479 5.264 1.622 7 3.374 7.011c1.463 0 2.649-.999 2.652-2.232v-.05c0 1.26 1.185 2.282 2.648 2.282 1.462 0 2.648-1.022 2.648-2.282 0 1.26 1.186 2.282 2.648 2.282 1.463 0 2.649-1.022 2.649-2.282v.05c0 1.233 1.185 2.232 2.648 2.232C21.019 7 22.162 5.265 21.402 3.77l-.963-1.573A2.5 2.5 0 0 0 18.307 1H4.424a2.5 2.5 0 0 0-2.08 1.113L1.239 3.769z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
